import React, { Component, lazy, Suspense } from "react";
import "bootstrap";
import "./assets/css/bootstrap.css";
import "../node_modules/font-awesome/scss/font-awesome.scss";
import "./App.scss";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import spinner from "./assets/img/spinner.gif";

const Header = lazy(() => import("./assets/js/header.js"));
const Footer = lazy(() => import("./assets/js/footer.js"));
// 404
const FourOFour = lazy(() =>
 import("./assets/js/components/pages/404.js")
);
//art
const HomePage = lazy(() =>
 import("./assets/js/components/pages/artwork/homepage.js")
);
const Cruz = lazy(() => import("./assets/js/components/pages/artwork/cruz.js"));
const StudyRight = lazy(() =>
 import("./assets/js/components/pages/artwork/study-right.js")
);
const KilluminatiArt = lazy(() =>
 import("./assets/js/components/pages/artwork/killuminati.js")
);
const AfricanDiamonds = lazy(() =>
 import("./assets/js/components/pages/artwork/african-diamonds.js")
);
const EarthGang = lazy(() =>
 import("./assets/js/components/pages/artwork/earthgang.js")
);
//pages
const About = lazy(() => import("./assets/js/components/pages/about.js"));
const Work = lazy(() => import("./assets/js/components/pages/work.js"));
const News1 = lazy(() => import("./assets/js/components/pages/news-1.js"));
const News2 = lazy(() => import("./assets/js/components/pages/news-2.js"));
const News3 = lazy(() => import("./assets/js/components/pages/news-3.js"));
const News4 = lazy(() => import("./assets/js/components/pages/news-4.js"));
const News5 = lazy(() => import("./assets/js/components/pages/news-5.js"));
const News6 = lazy(() => import("./assets/js/components/pages/news-6.js"));
const News7 = lazy(() => import("./assets/js/components/pages/news-7.js"));
const News8 = lazy(() => import("./assets/js/components/pages/news-8.js"));
const News9 = lazy(() => import("./assets/js/components/pages/news-9.js"));
const News10 = lazy(() => import("./assets/js/components/pages/news-10.js"));
const News11 = lazy(() => import("./assets/js/components/pages/news-11.js"));
const Contact = lazy(() => import("./assets/js/components/pages/contact.js"));
//portfolio internals
const Yat = lazy(() =>
 import("./assets/js/components/pages/internals/yat.js")
);

const Scubapoint = lazy(() =>
 import("./assets/js/components/pages/internals/scubapoint.js")
);
const Ripple = lazy(() =>
 import("./assets/js/components/pages/internals/ripple.js")
);
const GolfJunkies = lazy(() =>
 import("./assets/js/components/pages/internals/golfjunkies.js")
);
// const Yat = lazy(() => import("./assets/js/components/pages/internals/yat.js"));
const Banners = lazy(() =>
 import("./assets/js/components/pages/internals/banners.js")
);
const Illustrator = lazy(() =>
 import("./assets/js/components/pages/internals/illustrator.js")
);
const Kinesis = lazy(() =>
 import("./assets/js/components/pages/internals/kinesis.js")
);
const EarthGangPortfolio = lazy(() =>
 import("./assets/js/components/pages/internals/earthgangportfolio.js")
);
const TechCircus = lazy(() =>
 import("./assets/js/components/pages/internals/techcircus.js")
);
const CruzPortfolio = lazy(() =>
 import("./assets/js/components/pages/internals/cruzportfolio.js")
);
const Activewear = lazy(() =>
 import("./assets/js/components/pages/internals/activewear.js")
);
const Diamonds = lazy(() =>
 import("./assets/js/components/pages/internals/diamonds.js")
);
const Asos = lazy(() =>
 import("./assets/js/components/pages/internals/asos.js")
);
const KilluminatiPortfolio = lazy(() =>
 import("./assets/js/components/pages/internals/killuminatiportfolio.js")
);
const Deliveroo = lazy(() =>
 import("./assets/js/components/pages/internals/deliveroo.js")
);
const ArchibaldButler = lazy(() =>
 import("./assets/js/components/pages/internals/archibaldbutler.js")
);
const ThePixelLottery = lazy(() =>
 import("./assets/js/components/pages/internals/thepixellottery.js")
);
const BrewedOnline = lazy(() =>
 import("./assets/js/components/pages/internals/brewed-online.js")
);
const iTrust = lazy(() =>
 import("./assets/js/components/pages/internals/itrust.js")
);

// news internals
// const ThePixelLottery = lazy(() => import('./assets/js/components/pages/internals/the-pixel-lottery.js'))
const LondonPrestige = lazy(() =>
 import("./assets/js/components/pages/internals/london-prestige.js")
);
const GucciGig = lazy(() =>
 import("./assets/js/components/pages/internals/gucci-gig.js")
);
const DeckDesign = lazy(() =>
 import("./assets/js/components/pages/internals/deck-design.js")
);
const AdvancedPhotoshop = lazy(() =>
 import("./assets/js/components/pages/internals/advanced-photoshop.js")
);
const OneMega = lazy(() =>
 import("./assets/js/components/pages/internals/one-mega.js")
);
const emmaRidley = lazy(() =>
 import("./assets/js/components/pages/internals/emma-ridley.js")
);
const freeIcons = lazy(() =>
 import("./assets/js/components/pages/internals/free-icons.js")
);
const csmArtwork = lazy(() =>
 import("./assets/js/components/pages/internals/csm-illustrations.js")
);
const csmArtwork2 = lazy(() =>
 import("./assets/js/components/pages/internals/csm-illustrations-2.js")
);
const csmArtwork3 = lazy(() =>
 import("./assets/js/components/pages/internals/csm-illustrations-3.js")
);
const csmArtwork4 = lazy(() =>
 import("./assets/js/components/pages/internals/csm-illustrations-4.js")
);
const csmArtwork5 = lazy(() =>
 import("./assets/js/components/pages/internals/csm-illustrations-5.js")
);
const csmArtwork6 = lazy(() =>
 import("./assets/js/components/pages/internals/csm-illustrations-6.js")
);
//news page 2
const SurfIllustration = lazy(() =>
 import("./assets/js/components/pages/internals/surf-illustration.js")
);
const OurDesignAgency = lazy(() =>
 import("./assets/js/components/pages/internals/our-design-agency.js")
);
const MonkAndFoxIllustration = lazy(() =>
 import("./assets/js/components/pages/internals/monk-and-fox-illustration.js")
);
const RoamingGiraffe = lazy(() =>
 import("./assets/js/components/pages/internals/roaming-giraffe.js")
);
const Edwinsford = lazy(() =>
 import("./assets/js/components/pages/internals/edwinsford.js")
);
const Freddie2GetFit = lazy(() =>
 import("./assets/js/components/pages/internals/freddie2GetFit.js")
);
const BeachIllustration = lazy(() =>
 import("./assets/js/components/pages/internals/beach-illustration.js")
);
//news page 3
const Kaleidoscope = lazy(() =>
 import("./assets/js/components/pages/internals/kaleidoscope.js")
);
const GeekAward = lazy(() =>
 import("./assets/js/components/pages/internals/geek-award.js")
);
const AnimatedGif = lazy(() =>
 import("./assets/js/components/pages/internals/animated-gif.js")
);
const GreenYurts = lazy(() =>
 import("./assets/js/components/pages/internals/green-yurts.js")
);
const Rellik = lazy(() =>
 import("./assets/js/components/pages/internals/rellik.js")
);
const HowToBe = lazy(() =>
 import("./assets/js/components/pages/internals/how-to-be.js")
);
const BecomeOne = lazy(() =>
 import("./assets/js/components/pages/internals/become-one.js")
);
//news page 4
const ModelSquad = lazy(() =>
 import("./assets/js/components/pages/internals/model-squad.js")
);
const HmLocksmiths = lazy(() =>
 import("./assets/js/components/pages/internals/hm-locksmiths.js")
);
const BusinessCards = lazy(() =>
 import("./assets/js/components/pages/internals/business-cards.js")
);
const InteractiveMap = lazy(() =>
 import("./assets/js/components/pages/internals/interactive-map.js")
);
const Smokey = lazy(() =>
 import("./assets/js/components/pages/internals/smokey.js")
);
const WebUtopia = lazy(() =>
 import("./assets/js/components/pages/internals/web-utopia.js")
);
const SarahBeeny = lazy(() =>
 import("./assets/js/components/pages/internals/sarah-beeny.js")
);
const ArchiesWeb = lazy(() =>
 import("./assets/js/components/pages/internals/archies-web.js")
);
const CorporatePsd = lazy(() =>
 import("./assets/js/components/pages/internals/corporate-psd.js")
);

class App extends Component {
 // publish link for router<Router basename="/projects/archibald-butler-redesign-2019">
 //loading
 // state = {
 //   loading: true
 // }
 //end loading
 //console log
 componentDidMount() {
  console.log(
   "You can live your life on the edge of your sword... Or on the edge of your sofa"
  );

  //loading
  //demoAsyncCall().then(() => this.setState({ loading: false }));
  //end loading
 }

 render() {
  //loading
  // const { loading } = this.state;

  // if (loading) { // if your component doesn't have to wait for async data, remove this block
  //   return null; // render null when app is not ready
  // }
  //end loading

  return (
   <Router>
    <div className="App">
     <Suspense
      fallback={
       <div className="d-flex align-items-center h-100 w-100 loading">
        <img src={spinner} alt="Archibald Butler Loading" />
       </div>
      }
     >
      <Header />
      <Switch>
       <Route exact path="/" default component={HomePage} />

       <Route path="/animate-css-stories/" render={() => <Cruz />} exact />
       <Route path="/study-right/" component={StudyRight} />
       <Route
        path="/killuminati-first-political-cartoon/"
        component={KilluminatiArt}
       />
       <Route
        path="/political-cartoon-animations-africas-diamonds/"
        component={AfricanDiamonds}
       />
       <Route path="/earthgang-an-html-art-project/" component={EarthGang} />
       {/*pages*/}
       <Route path="/web-animation-specialist/" component={About} />
       <Route path="/work/" component={Work} />
       <Route path="/news-1/" component={News1} />
       <Route path="/news-2/" component={News2} />
       <Route path="/news-3/" component={News3} />
       <Route path="/news-4/" component={News4} />
       <Route path="/news-5/" component={News5} />
       <Route path="/news-6/" component={News6} />
       <Route path="/news-7/" component={News7} />
       <Route path="/news-8/" component={News8} />
       <Route path="/news-9/" component={News9} />
       <Route path="/news-10/" component={News10} />
       <Route path="/news-11/" component={News11} />
       <Route path="/contact/" component={Contact} />
       {/*news*/}
       <Route path="/scubapoint/" component={Scubapoint} />
       <Route path="/ripple/" component={Ripple} />
       <Route path="/golfjunkies/" component={GolfJunkies} />
       <Route path="/yat/" component={Yat} />
       <Route path="/banners/" component={Banners} />
       {/* <Route path="/yat/" component={Yat} /> */}
       <Route path="/illustrator/" component={Illustrator} />
       <Route path="/london-prestige/" component={LondonPrestige} />
       <Route path="/gucci-gig/" component={GucciGig} />
       <Route path="/free-illustrated-icons/" component={freeIcons} />
       <Route path="/emma-ridley/" component={emmaRidley} />
       <Route path="/one-mega-management/" component={OneMega} />
       <Route path="/advanced-photoshop/" component={AdvancedPhotoshop} />
       <Route path="/the-pixel-lottery/" component={ThePixelLottery} />
       <Route
        path="/skateboard-spray-design-archies-world/"
        component={DeckDesign}
       />
       <Route path="/brewed-online/" component={BrewedOnline} />

       <Route path="/itrust/" component={iTrust} />
       {/*news2*/}
       <Route
        path="/internals/ying-and-yang-illustration/"
        component={SurfIllustration}
       />
       <Route path="/our-design-agency/" component={OurDesignAgency} />
       <Route path="/cosmic-planet/" component={MonkAndFoxIllustration} />
       <Route path="/roaming-giraffe/" component={RoamingGiraffe} />
       <Route path="/edwinsford/" component={Edwinsford} />
       <Route path="/freddie-2-get-fit/" component={Freddie2GetFit} />
       <Route
        path="/fast-aquarelle-and-ink-drawing-illustration-beach-family-photo-reproduction/"
        component={BeachIllustration}
       />
       {/*news3*/}
       <Route path="/kaleidoscope/" component={Kaleidoscope} />
       <Route path="/geek-award/" component={GeekAward} />
       <Route path="/animated-gif/" component={AnimatedGif} />
       <Route path="/green-yurts/" component={GreenYurts} />
       <Route path="/rellik/" component={Rellik} />
       <Route path="/how-to-be/" component={HowToBe} />
       <Route path="/become-one/" component={BecomeOne} />
       {/* News 4 */}
       <Route path="/model-squad/" component={ModelSquad} />
       <Route path="/hm-locksmiths/" component={HmLocksmiths} />
       <Route path="/business-cards/" component={BusinessCards} />
       <Route path="/interactive-map/" component={InteractiveMap} />
       <Route path="/smokey/" component={Smokey} />
       <Route path="/web-utopia/" component={WebUtopia} />
       <Route path="/sarah-beeny/" component={SarahBeeny} />
       <Route path="/archies-web/" component={ArchiesWeb} />
       <Route path="/corporate-psd/" component={CorporatePsd} />
       {/*portfolio internals*/}
       <Route
        path="/kinesis-money-a-crypto-currency-ico-website/"
        component={Kinesis}
       />
       <Route path="/earthgang-portfolio/" component={EarthGangPortfolio} />
       <Route path="/techcircus-io/" component={TechCircus} />
       <Route path="/cruz-portfolio/" component={CruzPortfolio} />
       <Route path="/new-look-head-office/" component={Activewear} />
       <Route path="/diamonds/" component={Diamonds} />
       <Route path="/asos-jobs/" component={Asos} />
       <Route path="/killuminati-portfolio/" component={KilluminatiPortfolio} />
       <Route
        path="/freelancing-at-deliveroo-hq-in-london/"
        component={Deliveroo}
       />
       <Route
        path="/making-an-illustrated-website/"
        component={ArchibaldButler}
       />
       <Route path="/the-pixel-lottery/" component={ThePixelLottery} />
       <Route
        path="/illustrations-archibald-butler-art-school/"
        component={csmArtwork}
       />
       <Route path="/csm-artwork-2/" component={csmArtwork2} />
       <Route path="/csm-artwork-3/" component={csmArtwork3} />
       <Route path="/csm-artwork-4/" component={csmArtwork4} />
       <Route path="/csm-artwork-5/" component={csmArtwork5} />
       <Route path="/csm-artwork-6/" component={csmArtwork6} />
       <Route path="*" component={FourOFour} />
      </Switch>
      <Footer />
     </Suspense>
    </div>
   </Router>
  );
 }
}
//loading
// function demoAsyncCall() {
//   return new Promise((resolve) => setTimeout(() => resolve(), 500));
// }
//end loading
export default App;
